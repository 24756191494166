import {useToast} from "vue-toastification";
import i18n from "@/lang";
import store from "@/store";
import router from "@/router";
import IndexedDbFunctions from "@/assets/js/indexedDbFunctions";
import {appInstance} from "@/main";
import axios from "axios";
import timer from "@/routing/core/Tasks/Layouts/Timer.vue";

let logger;
let timerInterval = null;
export const state = {
  version: '0.10.60',
  development: process.env.NODE_ENV !== 'production',
  dbVersion: 4,
  dbName: 'sketchManagerAdmin',
  loggedInState: false,
  loadTitle: 'Lade Konfiguration',
  configLoader: {
    loadText: 'Bitte warten...',
    loadPercent: 0,
  },
  menu: null,
  publishNewVersion: false,
  languageOverrides: {
    items:[],
    de:{}
    },
  apiToken: '42Aj4EUnVYfqZd8F8MHtBjve549pV6GdwVUu4qZJLjtvfRTApv77jMfm9CZ7zTUE',
  // apiUrl: "https://uttenthaler-api.sketch-connect.de/",
  // apiUrl: "https://hfv-dev-api.sketch-manager.de/",
     // apiUrl: "https://spanner-api.sketch-connect.de/",
   // apiUrl: "https://api.bauchgefuehl-kinderzeit.de/",
  // apiUrl: "https://api-demo.sketch-connect.de/",
  //  apiUrl: "https://clean-crm-api.sketch-connect.de/",
   // apiUrl: "https://gries-api.sketch-connect.de/"
   // apiUrl: "https://api.bdfl.de/",
   //  apiUrl: "https://api.sketch.media/",
  //  apiUrl: "https://api.sketch.media/",
   //apiUrl: "https://hilger-api.sketch-connect.de/",
     apiUrl: "https://api.dfb-trainerbewerbung.de/",
    // apiUrl: "https://aurora-botarel-api.sketch-connect.de/",
  //apiUrl: "https://api-dev.dfb-trainerbewerbung.de/",
  // apiUrl: "https://api-time.schiffe-schnelltest.de/"
  // apiUrl: "https://api.via-nova-ostbayern.de/"
  configs: {},
  employees: {},
  draftAttachments: [],
  timer: {
    task: null,
    start: null,
    paused: false,
    formatted: null,
    completed: 0,
  }
}
export const mutations = {
  setDraftAttachments(state, payload) {
    state.draftAttachments = payload;
  },
  setConfig(state, payload) {
    state.configs[payload.key] = payload.data;
  },
  createStorage(state) {
    IndexedDbFunctions.addObject(state.dbName, 1, "core", {id: state.dbName, version: state.dbVersion});
  },
  saveJson(state) {
    IndexedDbFunctions.backup(state.dbName, state);
  },
  toggleConfigurator(state) {
    state.showConfig = !state.showConfig;
  },
  sidebarType(state, payload) {
    state.sidebarType = payload;
  },
  async setConfigLoader(state, payload) {
    state.configLoader = payload;
  },
  toggleSidebar(state) {
    state.sidebar = !state.sidebar;
  },
  handleApiResponse({commit}, shortResponse) {

    if (!shortResponse || !shortResponse.data || !shortResponse.data.todo) {
      return false;
    }
    const toast = useToast();
    let testMessage = shortResponse.data.todo.content;


    switch (shortResponse.data.todo.method) {
      case "showMessage":
        if (!i18n.de.toast.error[testMessage]) {
          toast.error(testMessage);
        } else {
          toast.error(i18n.de.toast.error[testMessage]);
        }
        break;
      case "logout":
        if (!i18n.de.toast.error[testMessage]) {
          toast.error(testMessage);
        } else {
          toast.error(i18n.de.toast.error[testMessage]);
        }
        store.commit("logout");
        break;
      case "logoutWithoutMessage":
        store.commit("logout");
        break;
      case 'profile':
        toast.error(shortResponse.data.todo.content);
        //router.push("/portal/profile");
        break;
      case 'terms':
        toast.error(shortResponse.data.todo.content);
        //router.push("/agb");
        break;
      default:
        alert("default in handleApiResponse");
    }
  },
  handleApiError(state, customMessage) {
    if (!state || !state.feedback) {
      return false;
    }
    state.feedback.type = "error";
    state.feedback.render = true;
    state.feedback.messageClass = "error";
    if (customMessage === undefined) {
      state.feedback.message = "Leider hat diese Aktion nicht geklappt. Versuche es erneut!";
    } else {
      state.feedback.message = customMessage;
    }
    setTimeout(function () {
      state.feedback.render = false
    }, 6000);
  },
  showAllDoneMessage(state, message) {
    state.feedback.type = "message";
    state.feedback.render = true;
    state.feedback.messageClass = "message";
    if (message === undefined) {
      state.feedback.message = "Vielen Dank!";
    } else {
      state.feedback.message = message;
    }
    setTimeout(function () {
      state.feedback.render = false
    }, 6000);
  },
  showErrorMessage(state, message) {
    state.feedback.type = "error";
    state.feedback.render = true;
    state.feedback.messageClass = "error";
    if (message === undefined) {
      state.feedback.message = "Vielen Dank!";
    } else {
      state.feedback.message = message;
    }
    setTimeout(function () {
      state.feedback.render = false
    }, 6000);
  },
  changeLoggedIn(state, todo) { // or changeLoggedIn(state)
    let checkSwitch = state.loggedInState;
    if (todo === "toNull") {
      state.loggedInState = null;
      localStorage.removeItem("sketchManagerToken");
    } else if (todo === true) {
      state.loggedInState = true;
    } else {
      state.loggedInState = !state.loggedInState; // use with this.$store.commit("changeLoggedIn")
    }
    if (checkSwitch !== state.loggedInState && state.loggedInState === true) {
      // router.push("/");
    }
    if (checkSwitch !== state.loggedInState && state.loggedInState === false) {
      router.push("/login");
    }
  },
  setToken(state, hash) { // or changeLoggedIn(state)
    state.token = !state.loggedInState; // use with this.$store.commit("changeLoggedIn")
    localStorage.setItem("sketchManagerToken", hash);
  },
  setAcl(state, acl) { // or changeLoggedIn(state)
    state.acl = acl; // use with this.$store.commit("changeLoggedIn")
  },
  setMenu(state, menu) {
    state.menu = menu;
  },
  setScope(state, acl) { // or changeLoggedIn(state)
    state.scope = acl; // use with this.$store.commit("changeLoggedIn")
  },
  setUser(state, user) { // or changeLoggedIn(state)
    state.user = user; // use with this.$store.commit("changeLoggedIn")
  },
  setApiToken(state, apiToken) { // or changeLoggedIn(state)
    state.apiToken = apiToken; // use with this.$store.commit("changeLoggedIn")
  },
  logout(state, redirect) {
    state.loggedInState = null;
    state.scope = {scope: null, write: false, loggedOut: true};
    state.acl = null;
    localStorage.removeItem("sketchManagerToken");
    if (redirect === undefined) {
      router.push("/signin");
    }
  },
  setFirstTimeLogin(state) {
    state.firstTimeLogin = false;
    localStorage.setItem("firstTimeLogin", "done");
  },
  setTimerLocalStorage(state) {
    // Adjusted to include new timer properties
    const timerToSave = {
      ...state.timer,
      startTime: state.timer.startTime,
      pauseTime: state.timer.pauseTime, // Save pauseTime to calculate paused duration
      completed: state.timer.completed, // No need to calculate here, just save the current state
    };
    localStorage.setItem("timer", JSON.stringify(timerToSave));
  },
  getTimerLocalStorage(state) {
    let timer = localStorage.getItem("timer");
    if (timer !== null) {
      state.timer = JSON.parse(timer);
      // Ensure that timerInterval doesn't start automatically; you'll manage this in actions
    }
    else {
      // Adjusted initial state to match new timer structure
      state.timer = {
        task: null,
        startTime: null,
        pauseTime: null,
        paused: false,
        completed: 0, // Ensure this matches the structure used elsewhere
      };
    }
  },



  forceSWupdate(state) {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.getRegistrations().then(function (registrations) {
        for (let registration of registrations) {
          registration.update();
        }
      })
    }
    if (localStorage.getItem("latest_cache_version") !== null && localStorage.getItem("latest_cache_version") !== 'null') {
      state.publishNewVersion = true;
    } else {
      state.publishNewVersion = false;
    }
  },
  setEmployees(state, payload) {
    state.employees = payload;
  },

  closeConfigCustomerLead(state) {
    state.activeItems.ConfigCustomerLead = null;
  },

  setActiveConfigCustomerLead(state, draft) {
    state.activeItems.ConfigCustomerLead = draft;
  },
  setActiveConfigCustomerAcquisitions(state, draft) {
    state.activeItems.ConfigCustomerAcquisitions = draft;
  },
  setActiveConfigCustomerPrio(state, draft) {
    state.activeItems.ConfigCustomerPrio = draft;
  },
  setActiveConfigCustomerType(state, draft) {
    state.activeItems.ConfigCustomerType = draft;
  },
  setActiveConfigCustomerTags(state, draft) {
    state.activeItems.ConfigCustomerTags = draft;
  },
  setActiveConfigBankAccounts(state, draft){
    state.activeItems.ConfigBankAccounts = draft;
  },
  setActiveConfigCustomerTaxes(state, draft){
    state.activeItems.ConfigCustomerTaxes = draft;
  },
  setActiveConfigAccountingKeys(state, draft){
    state.activeItems.ConfigAccountingKeys = draft;
  },
  setActiveConfigCostNumbers(state, draft){
    state.activeItems.ConfigCostNumbers = draft;
  },
  setActiveConfigTimeConfigs(state, draft) {
    state.activeItems.ConfigTimeConfigs = draft;
  },
  setActiveConfigCategoriesEmployee(state, draft) {
    state.activeItems.ConfigCategoriesEmployee = draft;
  },
  setActiveLanguageOverrides(state, draft) {
    state.activeItems.languageOverrides = draft;
  },
  setActiveConfigCustomerStructure(state, draft) {
    state.activeItems.ConfigCustomerStructure = draft;
  },
  setActiveConfigCountry(state, draft) {
    state.activeItems.ConfigCountryConfigs = draft;
  },
  setActiveConfigCustomerTitle(state, draft) {
    state.activeItems.ConfigCustomerTitle = draft;
  },
  setActiveIssueActivity(state, draft) {
    state.activeItems.IssueActivity = draft;
  },
  setActiveIssueTracker(state, draft) {
    state.activeItems.IssueTracker = draft;
  },
  setActiveIssueState(state, draft) {
    state.activeItems.IssueState = draft;
  },
  setActiveIssuePrio(state, draft) {
    state.activeItems.IssuePrio = draft;
  },
}
export const actions = {
  toast({}, payload) {
    const toast = useToast();
    if (!i18n.de.toast[payload.type][payload.message]) {
      toast[payload.type](payload.message);
    } else {
      toast[payload.type](i18n.de.toast[payload.type][payload.message]);
    }
  },
  timerStart({ state, commit }, payload) {
    if (state.timer.paused && state.timer.startTime) {
      // If the timer is paused, resume by calculating the offset time and setting the interval again
      const currentTime = Date.now();
      const pausedDuration = currentTime - state.timer.pauseTime;
      state.timer.startTime += pausedDuration; // Adjust startTime by pausedDuration
    } else {
      // Fresh start
      state.timer.completed = 0;
      state.timer.startTime = Date.now(); // Record start time
    }
    state.timer.task = payload;
    state.timer.paused = false;
    commit("setTimerLocalStorage");
    // Ensure any existing interval is cleared before starting a new one
    clearInterval(timerInterval);
    timerInterval = setInterval(() => {
      // Instead of directly incrementing 'completed', calculate elapsed time
      const currentTime = Date.now();
      state.timer.completed = currentTime - state.timer.startTime;
      commit("setTimerLocalStorage");
    }, 1000);
  },

  timerPause({ state, commit }) {
    clearInterval(timerInterval);
    state.timer.paused = true;
    state.timer.pauseTime = Date.now(); // Record the pause time
    commit("setTimerLocalStorage");
  },

  timerEnd({ commit, state, dispatch }, payload) {
    clearInterval(timerInterval);
    const currentTime = Date.now();
    state.timer.completed = currentTime - state.timer.startTime;
    let seconds = Math.floor(state.timer.completed / 1000);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);

    minutes = minutes % 60; // Correct way to calculate remaining minutes after hours are extracted

    // Convert minutes to a decimal of an hour
    let decimalMinutes = (minutes / 60).toFixed(2); // Keep two decimal places

    // Combine hours and decimal minutes for the final time
    // Ensure it's formatted as a float, not as a string concatenation
    let finalHours = parseFloat(hours + parseFloat(decimalMinutes));

    let finalPost = {
      activity_id: payload.activity_id,
      comment: payload.comment,
      hours: finalHours // Ensure it's a number
    };
    let headers = { "crmAuth": localStorage.getItem("sketchManagerToken") };
    axios.post(`${state.apiUrl}issue/${state.timer.task.id}/time`, finalPost, { headers }).then(() => {
      router.push({ name: "TasksTabs", params: { id: state.timer.task.id, tab: "details" } })
      state.timer.task = null;
      state.timer.startTime = null;
      state.timer.paused = false;
      state.timer.pauseTime = null; // Clear pauseTime
      commit("setTimerLocalStorage");
      // router push to task details

      // refresh task
    }).catch((e) => {
      dispatch("handleApiResponse", e.response);
      router.push({ name: "TasksTabs", params: { id: state.timer.task.id, tab: "details" } })
      state.timer.task = null;
      state.timer.startTime = null;
      state.timer.paused = false;
      state.timer.pauseTime = null; // Clear pauseTime
      commit("setTimerLocalStorage");
    });
  },
  timerReset({ commit, state }) {
    clearInterval(timerInterval);
    router.push({ name: "TasksTabs", params: { id: state.timer.task.id, tab: "details" } })
    state.timer.task = null;
    state.timer.startTime = null;
    state.timer.paused = false;
    state.timer.pauseTime = null;
    state.timer.completed = 0;
    commit("setTimerLocalStorage");
  },
  toastSuccess({dispatch}, payload) {
    dispatch("toast", {type: "success", message: payload});
  },
  toastWarning({dispatch}, payload) {
    dispatch("toast", {type: "warning", message: payload});
  },
  toastError({dispatch}, payload) {
    dispatch("toast", {type: "error", message: payload});
  },
  toastInfo({dispatch}, payload) {
    dispatch("toast", {type: "info", message: payload});
  },
  async getConfig({state}, config) {
    let key = config.type;
    if (config.subType !== undefined) {
      key = config.type + "_" + config.subType;
    }
    let returnVal;
    await IndexedDbFunctions.getObject(state.dbName, state.dbVersion, 'config', key).then(function (storedAssets) {
      returnVal = storedAssets;
    });
    return returnVal;
  },
  toggleSidebarColor({commit}, payload) {
    commit("sidebarType", payload);
  },
  copyToClipboard({commit}, copyText) {
    const toast = useToast();
    navigator.clipboard.writeText(copyText);
    toast.success(copyText + " kopiert!");
  },
  async configsLoaderProducts({dispatch}, loadFresh) {
    await dispatch("loadProducts", loadFresh);
  },
  async configsLoaderDrafts({dispatch}, loadFresh) {
    await dispatch("loadDrafts", loadFresh);
  },
  loadDraftAttachments({commit, getters}, payload) {
    const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
    axios.get(getters.getApiUrl + "draft/attachment/search", {headers}).then((r) => {
      commit("setDraftAttachments", r.data.result.data);
    });
  },
  async configsLoader({commit, dispatch, state}, loadFresh) {
    let configs = [
      {type: "employees", percent: 5, message: 'Lustige Nachrichten werden geladen'},
      {type: "customerCategories", percent: 10, message: 'Der Kaffee wird gebraut'},
      {type: "countries", percent: 15, message: 'Kekse werden gebacken'},
      {type: "countriesFilter", percent: 16, message: 'Cookies werden gebacken'},
      {type: "customerState", percent: 17, message: 'die Gedanken werden gesammelt'},
      {type: "bankAccounts", percent: 20, message: 'Geistreiche Dialoge werden erzeugt'},
      {type: "accountingKeys", percent: 25, message: 'Zeit und Raum werden vertauscht'},
      {type: "costNumbers", percent: 30, message: 'Bits werden gezüchtet'},
      {type: "customerTax", percent: 35, message: 'Fahrstuhlmusik wird geladen'},
      {type: "taxGroups", percent: 37, message: 'Fahrstuhlmusik läuft'},
      {type: "customerAcquisition", percent: 40, message: 'Warteschlange wird gefüttert'},
      {type: "customerPrio", percent: 45, message: 'Ihre Geduld wird auf die Probe gestellt'},
      {type: "customerLead", percent: 47, message: 'Ihre Geduld wird auf die Probe gestellt, nochmal...'},
      {type: "customerTypes", percent: 50, message: 'Erfolgschancen werden berechnet'},
      {type: "customerStructure", subType: 1, percent: 51, message: 'Bitte zerstören Sie nicht Ihren Bildschirm, es dauert nicht mehr lange'},
      {type: "customerStructure", subType: 2, percent: 52, message: 'Die Sahnehäubchen werden auf den Kuchen gesetzt'},
      {type: "customerStructure", subType: 3, percent: 53, message: 'Das Geheimnis des Lebens wird entschlüsselt'},
      {type: "customerStructure", subType: 4, percent: 54, message: 'Der Schlüssel zum Erfolg wird gesucht'},
      {type: "customerTags", percent: 55, message: 'Erfolgschancen werden berechnet'},
      {type: "project", subType: 'tags', percent: 56, message: 'Projektdetails werden geladen'},
      {type: "eventTags", percent: 57, message: 'Eventinfos werden geladen'},
      {
        type: "timeConfigs",
        percent: 58,
        message: 'Bitte zerstören Sie nicht Ihren Bildschirm, es dauert nicht mehr lange'
      },
      {
        type: "categories",
        subType: 'customers',
        percent: 60,
        message: 'Die Sahnehäubchen werden auf den Kuchen gesetzt'
      },
      {type: "categories", subType: 'products', percent: 62, message: 'Das Geheimnis des Lebens wird entschlüsselt'},
      {type: "categories", subType: 'documents', percent: 64, message: 'Der Schlüssel zum Erfolg wird gesucht'},
      {type: "categories", subType: 'drafts', percent: 66, message: 'Däumchen werden gedreht'},
      {type: "categories", subType: 'events', percent: 67, message: 'Däumchen werden gedreht'},
      {type: "categoriesEmployee", percent: 68, message: 'Fluxkompensator wird aktiviert'},
      {type: "countries", percent: 70, message: 'Kohle wird in den Server geschaufelt'},
      {type:"sketchdates", percent: 72, message: 'Termine werden gesucht'},
      {type: "customerTitle", percent: 75, message: 'Der Downloader wird heruntergeladen'},
      {type: "multilang", percent: 80, message: 'Die AGB werden gelesen'},
      {type: "companyPlanAccounting", percent: 83, message: 'Die Jahrespläne werden geschmiedet'},
      {type: "params", subType: 'values', percent: 85, message: 'Immer noch schneller als ein Windows-Update'},
      {type: "invoiceTypes", percent: 90, message: 'fast fertig... Schlussspurt'},
      {type: "project", subType: 'states', percent: 90, message: 'fast fertig... kurz vor Ziel'},
      {type: "issue", subType: 'states', percent: 94, message: 'fast fertig... kurz vor Ziel'},
      {type: "issue", subType: 'trackers', percent: 95, message: 'fast fertig... Ende in Sicht'},
      {type: "issue", subType: 'prios', percent: 97, message: 'fast fertig... noch 3%!'},
      {type: "issue", subType: 'activities', percent: 98, message: 'fast fertig... noch 2%!'},
    ];
    let a;
    if (loadFresh) {
      for (a = 0; a < configs.length; a++) {
        await dispatch("loadConfig", configs[a]);
      }
      await commit("setConfigLoader", {loadPercent: 99});
      await dispatch("configsLoaderProducts", true);

      await commit("setConfigLoader", {loadPercent: 100});
      await dispatch("configsLoaderDrafts", true);
      await commit("setConfigLoader", {loadPercent: 0});
    } else {
      // load from indexedDB
      let type;
      for (a = 0; a < configs.length; a++) {
        type = configs[a].type;
        if (configs[a].subType !== undefined) {
          type = type + "_" + configs[a].subType;
        }
        await IndexedDbFunctions.getObject(state.dbName, state.dbVersion, 'config', type).then(async (r) => {
          commit("setConfig", {key: type, data: r});
        });
      }
      await IndexedDbFunctions.getObjectList(state.dbName, state.dbVersion, 'products').then(async (r) => {
        commit("setProducts", r);
        commit("setAllProducts", r);
      });
      await IndexedDbFunctions.getObjectList(state.dbName, state.dbVersion, 'drafts').then(async (r) => {
      });
    }
    await dispatch("getPlugins");
    await dispatch("getEmployees");
    await dispatch("getLanguageOverrides");
  },
  login({commit, getters, dispatch}, payload) {

    if (appInstance) {
      logger = appInstance.config.globalProperties.$log;
    }
    if (!navigator.onLine) {
      commit("showErrorMessage", "Diese Funktion ist offline nicht verfügbar");
    } else {
      commit("changeLoggedIn", "toNull");
      axios.post(getters.getApiUrl + "login", payload).then(function (response) {
        let shortResponse = response.data.result;
        if (shortResponse.success === true) {
          commit("setToken", shortResponse.hash);
          const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
          axios.get(getters.getApiUrl + "checkMenu", {headers}).then(async (r) => {
            commit("setMenu", r.data.result.data);
            commit("setAcl", shortResponse.acl);
            commit("setUser", shortResponse.user);
            commit("setScope", shortResponse.scope);
            commit("setApiToken", shortResponse.apiToken);
            commit('changeLoggedIn', true);
            if (!shortResponse.user._isEmployee) {
              dispatch('toastError', 'Sie haben keinen Zugriff auf das CRM');
            } else {
              logger.debug("load config");
              await dispatch("configsLoader", true);
 let pusher = new Pusher('d4e91a37c1c6462058ae', {cluster: 'eu'});


              let channel = pusher.subscribe(getters.getApiToken);
              channel.bind('updateConfig', function (data) {
                if (data) {
                  dispatch("loadConfig", {type: data.type, subType: data.subType, percent: 0})
                }
              });

              await router.push({name: "Dashboard"});
            }
          });
        } else {
          dispatch("handleApiResponse", response.response);
        }
      }).catch(function (error) {
        if (error.code === "ERR_NETWORK") {
          // Server responded but with an error status
          dispatch("toastError", 'Derzeit liegt eine Störung vor. Bitte versuchen Sie es später erneut.');

        } else if (error.message) {
          // No response was received, API might be down
          dispatch("handleApiResponse", error.response);

        }
      });
    }
  },
  async getApiUrl ({ commit, state, dispatch }) {
    if (navigator.isOnline === undefined || navigator.isOnline) {

      let url = new URL(window.location.href);
      let host = url.host;


      await axios.get('https://api.sketch.media/urlAuth?domain='+host).then(function (response) {
        const shortResponse = response.data.result

        if (shortResponse.success === true ) {
          if (shortResponse.data) {
          state.apiUrl = shortResponse.data;
          }

          dispatch('checkToken');
        } else {

          commit('handleApiResponse', response)
        }

      }).catch(function (error) {

        commit('handleApiError', error)
      })
    }
  },
  async checkToken({commit, getters, dispatch}) {
    if (appInstance) {
      logger = appInstance.config.globalProperties.$log;
    }
    if (navigator.isOnline === undefined || navigator.isOnline) {
      let token = getters.getToken;
      if (token) {
        await axios.post(getters.getApiUrl + "checkToken", {token: token}).then(async function (response) {
          let shortResponse = response.data.result;
          if (shortResponse.success === true) {
            await commit("setToken", shortResponse.hash);
            await commit("setAcl", shortResponse.acl);
            await commit("setScope", shortResponse.user.scope);
            await commit('changeLoggedIn', true);
            await commit("setUser", shortResponse.user);
            await commit("setApiToken", shortResponse.apiToken);
            if (shortResponse.user._isEmployee) {
              const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
              axios.get(getters.getApiUrl + "checkMenu", {headers}).then(async (r) => {
                await commit("setMenu", r.data.result.data);
                await dispatch("configsLoader", false);
                var pusher = new Pusher('d4e91a37c1c6462058ae', {cluster: 'eu'});
                var channel = pusher.subscribe(getters.getApiToken);
                channel.bind('updateConfig', function (data) {
                  if (data) {
                    dispatch("loadConfig", {type: data.type, subType: data.subType, percent: 0})
                  }
                });
                return shortResponse.user.scope;
              });
            } else {
              await commit("logout");
            }
          } else {
            commit("handleApiResponse", response);
          }
        }).catch(function (error) {
          commit("handleApiError", error);
        });
      }
    }
  },
  logout({commit}) {
    commit("logout");
  },
  handleApiError({commit}, error) {
    commit("handleApiError", error);
  },
  handleApiResponse({commit}, response) {
    commit("handleApiResponse", response);
  },
  async getPlugins({commit, getters}) {
    const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
    axios.get(getters.getApiUrl + "plugins", {headers}).then(function (response) {
      commit("setPlugins", response.data);
    }).catch(function (e) {
    });
  },
}
export const getters = {
  getConfigs: state => {
    return state.configs;
  },
  getSelectedCountries: state => {
    var selected = [];
    for (var key in state.configs.countries) {
      if (state.configs.countries[key].state === "1" || state.configs.countries[key].state === 1) {
        selected.push(state.configs.countries[key]);
      }
    }
    return selected;
  },
  getToken() {
    return localStorage.getItem("sketchManagerToken");
  },
  getPlugins: state => {
    return state.plugins;
  },
  getEmployees: state => {
    return state.employees;
  },
  getUser: state => {
    return state.user;
  },
  checkLogin: state => {
    return state.loggedInState// use with this.$store.getters.checkLogin
  },
  getApiUrl: state => {
    return state.apiUrl;
  },
  /**
   * @deprecated since 0.8.4
   * @returns {{crmAuth: string}}
   */
  getAuthorization() {
    return {"crmAuth": localStorage.getItem("sketchManagerToken")};
  },
  getMenu: state => {
    return state.menu;
  },
  getDraftAttachments: state => {
    return state.draftAttachments;
  },
  getLanguageOverrides: state => {
    return state.languageOverrides;
  },
  getTimer: state => {
    // get Hours and Minutes from state.timer.completed
    let seconds = Math.floor(state.timer.completed / 1000);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);
    minutes = minutes - (hours * 60);
    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    state.timer.formatted = `${hours}:${minutes}`;
    return state.timer;
  },
  getApiToken: state => {
    return state.apiToken;
  }
}
