<template>
  <div class="productItem activeProduct">
    <div class="card">
      <div class="card-header ">
        <h6 class="inline"><i class="fa-duotone fa-square-info"></i> Vorlage</h6>
        <button type="button" class="btn btn-close float-end ms-4"
                @click="$store.commit('closeDraft');$store.commit('closeModal', 'draft');$router.push({name: 'drafts'})">
        </button>
        <div class="btn-group float-end">
          <button type="button" class="btn btn-outline-danger btn-sm" v-if="d.id" @click="deleteDraft()"
                  :disabled="d.isSystemDraft === 'yes'"><i
            class="fa-duotone fa-trash"></i> Löschen
          </button>
          <button type="button" class="btn btn-outline-primary btn-sm me-4" @click="updateDraft()"><i
            class="fa-duotone fa-floppy-disk"></i> Speichern
          </button>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-lg-9 col-12">
            <p v-if="d.isSystemDraft === 'yes'"><i class="fa-duotone fa-info-circle"></i> Die Systemvorlage
              ({{ d.systemKey }}) kann nicht gelöscht werden</p>
            <div class="row">
              <div class="col-lg-6 col-12">
                <div class="form-group">
                  <label>Bezeichnung</label>
                  <input class="form-control" type="text" v-model="d.title">
                </div>
              </div>
              <div class="col-lg-3 col-12 ">
                <div class="form-group">
                  <label>Kategorie</label>
                  <select v-model="d.group_id" class="form-select">
                    <option :value="cat.id" v-for="cat in getConfigs.categories_drafts" :key="cat.id">{{
                        cat.title
                      }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-lg-3 col-12">
                <div class="form-group">
                  <label>Sortierung</label>
                  <input class="form-control" type="number" v-model="d.ordering">
                </div>
              </div>
              <div class="col-lg-6 col-12">
                <div class="form-group">
                  <label>Datei-Anhang</label>
                  <select v-model="d.attachment_id" class="form-select">
                    <option value="0">- Kein Anhang ausgewählt -</option>
                    <option :value="att.id" v-for="att in getDraftAttachments" :key="att.id">{{
                        att.title
                      }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-lg-6 col-12">
                <div class="form-group">
                  <label>Status</label>
                  <select v-model="d.draft_state" class="form-select">
                    <option value="1">Aktiv</option>
                    <option value="-1">Archiviert</option>
                    <option value="-2">Gelöscht</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label>Text</label>
              <template v-if="editorMode === 'text'">
                <button @click="editorMode = 'html'" class="btn ms-3 mt-3 mb-3 btn-sm btn-outline-primary"><i
                  class="fa-duotone fa-code"></i> HTML-Editor
                </button>
                <Editor2 v-model="d.draft"/>
              </template>
              <template v-else>
                <button @click="editorMode = 'text'" class="btn ms-3 mt-3 mb-3 btn-sm btn-outline-primary"><i
                  class="fa-duotone fa-text-size"></i> Text-Editor
                </button>
                <EditorHtml v-model="d.draft"/>
              </template>
            </div>
            <div class="row">
              <div class="col-lg-6 col-12">
                <div class="form-group"><input type="text" v-model="filetitle" placeholder="Dateiname eingeben, dann Datei hochladen" class="form-control small" />
                  </div></div>
              <div class="col-6" v-if="filetitle !==''">
                <UploadComponent :compact="true" :draft-id="d.id" v-if="d && d.id" :filename="filetitle"  />
              </div>
            </div>
            <div class="row" v-if="dev">
              <div class="col-12">
                <div class="form-group mt-3">
                  <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" id="usePdf" v-model="usePdf">
                    <label class="form-check label" for="usePdf">PDF verwenden</label>
                    </div>
                </div>
                <PdfEditor v-if="usePdf"  />
              </div>
            </div>


          </div>
          <div class="col-lg-3 col-12">
            <div class="draftVariables" v-if="draftVariables && draftVariables.length > 0">
              <p class="mb-1"><span style="font-weight: bold;" >Verfügbare Variablen</span> <span tooltip="Mit Klick kopieren"><i class="fa-duotone fa-info-circle"></i></span></p>
              <ul class="list-group" style="margin-left: 0;">
                <template v-for="dvar in draftVariables">
                  <li class="list-group-item " @click="insertText(dvar)"><span class="dvarName">{{dvar.name}}</span><span class="dvarValue" v-html="dvar.value"></span>
                  </li>
                </template>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import Editor2 from "@/components/Editor2";
import EditorHtml from "@/components/EditorHtml";
import {mapGetters} from "vuex";
import Headline from "@/routing/core/Drafts/Layouts/Headline.vue";
import UploadComponent from "@/directives/Upload/UploadComponent.vue";
import PdfEditor from "@/routing/core/Drafts/Layouts/PdfEditor.vue";
import $ from "jquery";

export default {
  name: "DraftDetails",
  components: {
    Headline,
    UploadComponent,
    Editor2,
    EditorHtml,
    PdfEditor
  },
  computed: {
    ...mapGetters(["getEmployees", "draftActive", "getConfigs", "getDraftAttachments"])
  },
  data() {
    return {
      d: {
        draft: ' '
      },
      filetitle: '',
      isNew: true,
      editorMode: 'text',
      draftVariables: [],
      usePdf: false,
      dev: false
    };
  },
  methods: {
    updateDraft() {
      let VueInner = this;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      if (VueInner.d.id !== undefined) {
        axios.put(this.$store.state.apiUrl + "draft/" + VueInner.d.id, VueInner.d, {headers}).then(() => {
          VueInner.$store.commit('closeDraft');
          // dispatch toastSuccess
          VueInner.$store.dispatch('toastSuccess', 'Draft wurde erfolgreich gespeichert');
          // Load Products
          VueInner.$store.dispatch('draftsFilter');
          this.$router.push({name: 'drafts'});
        }).catch((r) => {
          this.$store.dispatch("handleApiResponse", r.response);
        });
      } else {
        axios.post(this.$store.state.apiUrl + "draft", VueInner.d, {headers}).then(() => {
          VueInner.$store.commit('closeDraft');
          this.$store.commit('closeModal', 'draft');
          // dispatch toastSuccess
          VueInner.$store.dispatch('toastSuccess', 'Vorlage wurde erfolgreich gespeichert');
          // Load Products
          VueInner.$store.dispatch('draftsFilter');
          this.$router.push({name: 'drafts'});
        }).catch((r) => {
          this.$store.dispatch("handleApiResponse", r.response);
        });
      }
    },
    getDraftVariables() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "draft/variables", {headers}).then((r) => {
        this.draftVariables = r.data.result.data;
      });
    },
    deleteDraft() {
      let VueInner = this;
      this.$confirm('Soll die Vorlage wirklich gelöscht werden?', 'Vorlage löschen', "question", {
        confirmButtonText: 'Ja',
        cancelButtonText: 'Nein',
        confirmButtonColor: '#6f42c1',
        type: 'warning'
      }).then(() => {
        const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
        axios.delete(this.$store.state.apiUrl + "draft/" + VueInner.d.id, {headers}).then(() => {
          // close
          VueInner.$store.commit('closeDraft');
          // dispatch toastSuccess
          VueInner.$store.dispatch('toastSuccess', 'Vorlage wurde erfolgreich gelöscht');
          // Load Products
          VueInner.$store.dispatch('draftsFilter');
        }).catch((r) => {
          this.$store.dispatch("handleApiResponse", r.response);
        });
      }).catch((r) => {
        console.log(r);
      });
    },
    insertText(dvar) {
      this.$store.dispatch('copyToClipboard', dvar.name);
    },
    loadDraft() {
      if (this.$route.params.id) {
        this.d.id = this.$route.params.id;
        const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
        axios.get(this.$store.state.apiUrl + "draft/" + this.$route.params.id, {headers}).then((r) => {
          this.d = r.data.result.data;
          this.$store.dispatch("setActiveDraft", this.d);
          if (!this.d.id) {
            this.store.dispatch("toastError", "Vorlage nicht gefunden");
            this.$router.push({name: 'drafts'});
          } else {
            this.scrollToTop();
          }
        }).catch((r) => {
          this.$store.dispatch("handleApiResponse", r.response);
        });
        this.isNew = false;
      } else {
        this.isNew = true;
      }
    },
    scrollToTop() {
      $("html, body").animate({scrollTop: 0});
    },
    handleUploadFinished() {
      this.filetitle='';
      this.$store.dispatch("loadDraftAttachments");
      this.$store.dispatch("toastSuccess","Die kann nun in Datei-Anhang ausgewählt werden.")
    }
  },
  created() {
    this.loadDraft();
    this.getDraftVariables();
    this.$store.dispatch("loadDraftAttachments");
  },
  watch: {
    $route(to, from) {
      this.loadDraft();
    }
  },
  mounted() {
    window.addEventListener('triggerReloadFiles', this.handleUploadFinished);
  },
  beforeUnmount() {
    window.removeEventListener('triggerReloadFiles', this.handleUploadFinished);
  },
};
</script>

